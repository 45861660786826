import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { utils } from "near-api-js";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import DashboardProfile from "./dashoard-profile";
import DashboardAdmin from "./dashboard-admin";
import notify from "../utils/notify";
import { getBalance } from "../utils/utilities";
import { setProfileFeatured, revertProfileFeatured } from "../api";




const DashboardMain = ({ location }) => {

  const { auth, getUser } = useContext(AuthContext);
  const {
    getContratcsList,
    setUnreadContracts,
    setUserStats,
    featureProfileFee,
  } = useContext(DataContext);
  const { selector, accountId } = useWalletSelector()

  const [loading, setLoading] = useState(true);
  const [missingProfileInfo, setMissingProfileInfo] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const params = new URLSearchParams(location.search);
  let publicKey = params.get("public_key");
  let transactionHashes = params.get("transactionHashes");


  const fetchContracts = async (accountId, accountType) => {
    const response = await getContratcsList(accountId, accountType);
    const unreadContracts = response.filter(
      ({ status }) => status === "pending"
    );
    const completedProjects = response.filter(({ status }) =>
      ["completed", "approved"].includes(status)
    );
    const inprogressProjects = response.filter(
      ({ status }) => status === "ongoing"
    );
    const revenueGenerated = completedProjects.reduce(
      (a, b) => a + b.talentFee,
      0
    );
    const userStats = {
      completedProjects: completedProjects.length || 0,
      inprogressProjects: inprogressProjects.length || 0,
      revenueGenerated: revenueGenerated || 0,
    };
    await setUnreadContracts(unreadContracts.length || null);
    await setUserStats(userStats);
  };

  const fetchUserData = async (accountId) => {
    try {
      // await isAuthenticated();
      const response = await getUser(accountId);
      if (response === false) {
        // navigate("/profile-update", { replace: true });
        navigate("/profile-update");
      } else if (response?.message) {
        notify(response.message);
        localStorage.clear();
        navigate("/", { replace: true });
      } else {
        localStorage.setItem("auth", JSON.stringify(response));
        if (!response?.admin) {
          const id =
            response?.talent?.account_id || response?.client?.account_id;
          const accountType = response?.talent ? "talent" : "client";
          await fetchContracts(id, accountType);
        }
        const missingValues = {
          "Profile Picture":
            response?.talent?.imageUrl.trim().length === 0 ? true : false,
          "Work Experience":
            response?.talent?.workExperience &&
              response?.talent?.workExperience.length === 0
              ? true
              : false,
        };
        const missingInfo = Object.keys(missingValues).filter(
          (key) => missingValues[key] === true
        );
        if (missingInfo.length > 0) {
          setMissingProfileInfo(missingInfo.toString().replaceAll(",", ", "));
        }
        // Get Wallet Balance
        const balance = await getBalance(response?.talent?.account_id);
        if (balance?.type === "HANLDER_ERROR" || balance?.message) {
          setWalletBalance(0.0);
        } else {
          setWalletBalance(parseFloat(balance.total));
        }

        navigate("/dashboard-main", { replace: true });
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      navigate("/", { replace: true });
    }
  };

  const handleFeatureProfile = async () => {
    setLoading(true);
    const amountInYocto = utils.format.parseNearAmount(
      featureProfileFee.toString()
    );
    try {
      const wallet = await selector.wallet()
      const response = await wallet
        .signAndSendTransaction({
          signerId: accountId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "feature_profile",
                args: { receiver: process.env.GATSBY_ADMIN_WALLET },
                gas: "300000000000000",
                deposit: amountInYocto,
              },
            },
          ],
        })
      console.log(response)
      if (response) {
        await enablefeaturedProfile(accountId)
        navigate(`/dashboard-main`, { replace: true })
      }
    } catch (err) {
      notify("Failed to make featured");
      console.log(err);
    };

  };

  const handleRevertFeatureProfile = async () => {
    setLoading(true);
    const response = await revertProfileFeatured(auth?.talent?.account_id);
    if (response) {
      await fetchUserData(auth?.talent?.account_id);
    }
    setLoading(false);
  };

  const enablefeaturedProfile = async (account_id) => {
    const response = await setProfileFeatured(account_id);
    if (response) {
      notify("Profile featured successfully", "success");
      await fetchUserData(account_id);
    }
  };

  useEffect(() => {


    if (accountId && publicKey) {

      localStorage.setItem("account_id", accountId);
      localStorage.setItem("public_key", publicKey);
      // Check if wallet id exists then redirect to dashboard
      fetchUserData(accountId);
    } else if (transactionHashes) {
      //accountId = window.localStorage.getItem("account_id");
      enablefeaturedProfile(accountId);
    } else {
      if (auth) {
        if (!auth?.admin) {
          const id = auth?.talent?.account_id || auth?.client?.account_id;
          const accountType = auth?.talent ? "talent" : "client";
          fetchContracts(id, accountType);
        }

        // Fetch image and save url to storage
        // accountId = window.localStorage.getItem("account_id");
        fetchUserData(accountId);
        setLoading(false);
      } else {
        //accountId = window.localStorage.getItem("account_id");
        // Check if wallet id exists then redirect to dashboard
        fetchUserData(accountId);
      }
    }
    return () => setLoading(true);
  }, []);

  if (auth?.admin) {
    return <DashboardAdmin />;
  }

  return (
    <DashboardProfile
      loading={loading}
      handleFeatureProfile={handleFeatureProfile}
      handleRevertFeatureProfile={handleRevertFeatureProfile}
      missingProfileInfo={missingProfileInfo}
      walletBalance={walletBalance}
      enablefeaturedProfile={enablefeaturedProfile}
    />
  );
};

export default DashboardMain;
