import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { AuthContext } from "../context/AuthContext";
import { getStats } from "../api";

const DashboardAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const { auth, profileView } = useContext(AuthContext);

  const fetchStats = async () => {
    const response = await getStats();
    // console.log("RESPONSE ", response);
    setStats(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchStats();
    return () => setLoading(true);
  }, []);

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25 mt-lg-31">
          <div className="container">
            <div className="row mb-7">
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {loading ? (
                  <Skeleton height={124} width={290} />
                ) : (
                  <Link
                    to="/dashboard-admin-jobs"
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                  >
                    <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            <CountUp duration={1} end={stats?.jobs?.length} />
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                        Posted Jobs
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {loading ? (
                  <Skeleton height={124} width={290} />
                ) : (
                  <Link
                    to="/dashboard-admin-clients"
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                  >
                    <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                      <i className="fas fa-user"></i>
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            <CountUp duration={1} end={stats?.clients?.length} />
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                        Clients
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {loading ? (
                  <Skeleton height={124} width={290} />
                ) : (
                  <Link
                    to="/dashboard-admin-talents"
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                  >
                    <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                      <i className="fas fa-eye"></i>
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            <CountUp duration={1} end={stats?.talents?.length}
                            />
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                        Talents
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                {loading ? (
                  <Skeleton height={124} width={290} />
                ) : (
                  <Link
                    to="/dashboard-admin-contracts"
                    className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
                  >
                    <div className="text-egg-blue bg-egg-blue-opacity-1 circle-56 font-size-6 mr-7">
                      <i className="fas fa-mouse-pointer"></i>
                    </div>
                    <div className="">
                      <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                        <LazyLoad>
                          <span className="counter">
                            <CountUp duration={1} end={stats?.contracts?.length}
                            />
                          </span>
                        </LazyLoad>
                      </h5>
                      <p className="font-size-4 font-weight-normal text-gray mb-0">
                        Contracts
                      </p>
                    </div>
                  </Link>
                )}
              </div>
            </div>

            <div className="mb-18">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">{profileView}
                    {" "}
                    Overview
                  </h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <div className="h-px-48">
                      <h3 className="font-size-4 text-gray font-weight-normal mb-0">
                        {auth?.admin?.account_id}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bg-white shadow-8 pt-7 rounded pb-9 px-11">
              </div> */}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardAdmin;
